import { List, Map } from '@mui/icons-material'
import Switch from '@mui/material/Switch'
import GoogleMap from 'components/maps'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CollectionTable } from '../../organisms'
import styles from './CollectionPage.module.css'
import {
BottomNavigation,
BottomNavigationAction,
Container,
FormControlLabel
} from '@mui/material'
import { usePageDispatch, useFiltered } from 'providers/PageProvider'
import { useGeocodeDispatch } from 'providers/GeocodeProvider'

import { sameDayReturnOutcomes } from 'constants/outcomes'

/**
 * Shows a summary of appointments for a single collection. This can be either a
 * list or plotted on a map.
 */
const CollectionPage = ({ collection, match, mass, ...rest }) => {
  const [view, setView] = useState('list')
  const [locations, setLocations] = useState([])
  
  const filtered = useFiltered()
  const [filteredInst, setFilteredInst] = React.useState([])
  const [mapReady, setMapReady] = useState(false)
  const {
    hasAllGeocodes,
    getLocationObjects,
    setInstallations
  } = useGeocodeDispatch() 
  const { setFiltered, setCount, setId } = usePageDispatch()

  useEffect(() => {
    if (hasAllGeocodes()) {
      setLocations(getLocationObjects())
      setMapReady(true)
    } 
  }, [getLocationObjects, hasAllGeocodes])

  const handleChange = (event, view) => {
    setView(view)
  }

  const handleSwitchChange = () => {
    setFiltered(!filtered)
  }

  useEffect(() => {
    const { installations = [] } = collection || []
    /**
     * Sort the installations properly based on the appointment ordering. If the
     * appointment properties don't exist, then will simply fallback to standard
     * order.
     */
    const sorted =
      (collection?.engineering &&
        [...installations].sort(
          (prev, next) =>
            prev?.appointment?.ordering - next?.appointment?.ordering
        )) ||
      installations

    /**
     * If the filter value is set to true then all appointments with a non-blue
     * status colour where an installer may return and update the outcome the
     * same day should be filtered out, only default scheduled appointments and
     * rescheduled appointments with the approved outcome detail above should appear.
     */
    let tmpFiltered = []
    if (filtered) {
      sorted.forEach(element => {
        if (
          element.outcome.type === 0 ||
          sameDayReturnOutcomes.some(option =>
            element?.appointment?.outcomeDetail?.includes(option)
          ) ||
          sameDayReturnOutcomes.some(option =>
            element?.outcomes?.[element?.outcomes?.length - 1]?.notes?.includes(
              option
            )
          )
        ) {
          tmpFiltered.push(element)
        }
      })
    } else {
      tmpFiltered = sorted
    }

    setCount(tmpFiltered.length)
    setFilteredInst(tmpFiltered)
    setInstallations(tmpFiltered)
    setId(collection?.id)
  }, [collection?.engineering, collection?.id, filtered, collection, setCount, setId, setInstallations])

  return (
    <>
      <Container sx={{ mt: 3, mb: 11 }}>
        <FormControlLabel
          control={<Switch onChange={handleSwitchChange} checked={filtered} color='info' />}
          label={
            filtered
              ? 'Only Showing Incomplete Appointments'
              : 'Filter for Incomplete Appointments'
          }
        />

        {view === 'list' && (
          <CollectionTable
            collection={collection}
            mass={mass}
            installations={filteredInst}
          />
        )}
        {view === 'map' && (
          <GoogleMap
            collection={collection}
            locations={locations}
            hasAllGeocodes={mapReady}
          />
        )}
      </Container>

      <BottomNavigation
        className={styles.bottomNavigation}
        onChange={handleChange}
        showLabels
        value={view}
      >
        <BottomNavigationAction icon={<List />} label='List' value='list' />
        <BottomNavigationAction icon={<Map />} label='Map' value='map' />
      </BottomNavigation>
    </>
  )
}

/**
 * Some Redux magic to convert Redux state properties to standard Component
 * props. This makes it super-easy for us to work with data in a more intuitive,
 * presentational fashion.
 */
const mapStateToProps = (state, ownProps) => {
  const mass = state.masses[ownProps.match?.params.massId]
  if (!mass) return {}
  /** Parsing int's to make sure strict comparison works robustly */
  const collection = mass.collections.find(
    c => parseInt(c.id) === parseInt(ownProps?.match.params.collectionId)
  )
  return { mass, collection }
}

export default connect(mapStateToProps)(CollectionPage)
