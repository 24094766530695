/**
 * Environment variables are defined in .env files.
 */
const apiRoot = process.env.REACT_APP_PELLONIA_API_ROOT

export const fetchInstallation = (id) => ({
  type: 'installation/fetch',
  payload: { id },
  meta: {
    offline: {
      // The network action to execute:
      effect: {
        method: 'GET',
        url: `${apiRoot}installations/api/installations/${id}`
      },
      commit: { type: 'installation/fetch/commit' }
    }
  }
})
