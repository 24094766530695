import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Notification, NotificationManager } from 'app/managers'
import { populateWithFetch } from '../reducers/helpers'

const initialState = {
  masses: [],
  status: 'idle',
  error: null
}

const apiRoot = process.env.REACT_APP_PELLONIA_API_ROOT

/**
 * An async thunk, which triggers context-aware actions when it resolves. This
 * allows us to keep track of async activity and to trigger state and UI logic
 * depending on the outcome of the activity defined within it.
 *
 * Here, a network request is made to obtain a fresh set of mass objects.
 */
export const fetchMasses = createAsyncThunk(
  'masses/fetchMasses',
  async credential => {
    let data
    try {
      const response = await window.fetch(
        `${apiRoot}installations/api/masses`,
        {
          headers: {
            Authorization: `Bearer ${credential}`
          }
        }
      )
      data = await response.json()
      if(!response.ok) {
        return Promise.reject(data.reason || 'There was a problem while checking for updates.')
      }
      return data
    } catch (error) {
      return Promise.reject('There was a problem while checking for updates.')
    }
  }
)

/**
 * Defines the slice for handling mass actions. By calling `.reducer` the
 * reducer for this slice is magically returned.
 */
const massesSlice = createSlice({
  name: 'masses',
  initialState,
  reducers: {},
  extraReducers (builder) {
    builder
      .addCase(fetchMasses.pending, (state, action) => {
        state.massStatus = 'loading'
      })
      .addCase(fetchMasses.fulfilled, (state, action) => {
        delete state.error
        state.massStatus = 'succeeded'
        populateWithFetch(state, action.payload)

        const notification = new Notification({
          detail: 'New data has been fetched.',
          severity: 'success'
        })

        NotificationManager.addNotification(notification)
      })
      .addCase(fetchMasses.rejected, (state, action) => {
        state.massStatus = 'failed'
        state.error = action.error.message

        const notification = new Notification({
          detail: state.error,
          severity: 'error'
        })
        NotificationManager.addNotification(notification)
      })
  }
})

export default massesSlice.reducer
