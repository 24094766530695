import UUID from 'utils/UUID'

const envosenseApiRoot = process.env.REACT_APP_ENVOSENSE_API_ROOT

/**
 * Attempts to post an envosense attach device request to the envosense api.
 * All retry and rollback mechanisms are handled by redux-offline and configured
 * in getStore.js.
 *
 * @param {*} effect The data to include in the post request
 * @param {*} uuid A unique identifier for the patch
 * @param {*} fullName The full name of the customer for display in the UI
 * @param {*} address The address of the customer for display in the UI
 */
export const patchEnvosense = (effect, fullName, address, installationId, uuid = UUID()) => ({
  type: 'envosense/patch',
  payload: { effect: effect, fullName: fullName, address: address, installationId: installationId, uuid: uuid },
  meta: {
    offline: {
      // The network action to execute:
      effect: {
        json: effect,
        method: 'POST',
        url: `${envosenseApiRoot}v1/attachdevices`,
        headers: { 'Content-Type': 'application/json' }
      },
      // Action to dispatch when effect succeeds:
      commit: { type: 'envosense/patch/commit', meta: { uuid } },
      // Action to dispatch when effect has failed permanently:
      rollback: { type: 'envosense/patch/rollback', meta: { uuid } }
    },
    uuid: uuid
  }
})
